.c-post-image__image {
  margin-bottom: 1rem;
  width: 100%;

  @include bp-large {
    margin-bottom: 0;
  }
}

.c-post-image__content {
  @include bp-small-max {
    margin-left: 0;
    width: calc(100% + #{$grid-gutter-width-base});
  }

  &--padding {
    @include bp-medium-max {
      width: 100%;
    }
  }
}

.c-post-image__frame-container {
  // 9/16 = 0.5625
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  margin-bottom: 1.2rem;
}

.c-post-image__frame,
.c-post-image__frame-container iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}

.c-post-image__container {
  display: flex;
  align-items: center;
  flex-direction: column;

  @include bp-large {
    flex-direction: row;
  }
}


.c-post-image__description {
  font-size: 1.4rem;
  line-height: 1.34;
  color: $color-light-grey;

  @include bp-large {
    font-size: 1.5rem;
  }

  @include bp-full {
    font-size: 1.8rem;
  }

  &--left {
    text-align: right;
    display: none;
    @include bp-large {
      display: block;
    }
  }

  &--bottom {
    display: block;
    @include bp-large {
      display: none;
    }
  }

  p {
    margin-bottom: 0;
  }
}
