.c-post-header__image {
  display: none;
  position: relative;

  &:before {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    bottom: 0;
    content: "";
    height: 32px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 0;

    @include bp-medium {
      height: 48px;
    }

    @include bp-large {
      height: 60px;
    }
  }
}

.c-post-header__bg-image-outer {
  max-height: 480px;
  position: relative;
  overflow: hidden;
}

.c-post-header__bg-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  padding-bottom: 43.75%;
}

.c-post-header__image-image {
  display: block;
  width: 100%;
}

.c-post-header__bottom {
  position: relative;
  padding-top: 3rem;

  @include bp-small {
    padding-top: 4rem;
  }

  @include bp-medium {
    padding-top: 6rem;
  }

  @include bp-full {
    padding-top: 9rem;
  }

  &--no-banner {
    padding-top: 3.6rem;

    @include bp-medium {
      padding-top: 4rem;
    }
  }
}

.c-post-header__avatar {
  position: absolute;
  overflow: hidden;
  border: 2px solid $color-white;
  @include avatar-with-size(70px);

  @include bp-medium {
    @include avatar-with-size(80px);
    left: calc(6.25% + 30px);
  }

  @include bp-large {
    @include avatar-with-size(120px);
    border-width: 3px;
  }

  @include bp-full {
    @include avatar-with-size(150px);
    border-width: 3px;
    left: auto;
  }
}

.c-post-header__avatar-image {
  width: 100%;
  height: 100%;
}

.c-post-header__heading {
  margin-bottom: 0;
  font-size: 3.4rem;
  font-weight: normal;
  line-height: 1.2;
  letter-spacing: -0.01em;

  @include bp-small {
    font-size: 3.6rem;
  }

  @include bp-medium {
    font-size: 4.2rem;
  }

  @include bp-large {
    font-size: 4.8rem;
  }

  @include bp-large2 {
    font-size: 6.4rem;
  }

  @include bp-full {
    font-size: 7rem;
    letter-spacing: -0.02em;
  }
}

.c-post-header__short {
  margin-bottom: 0;
  margin-top: 2rem;
  font-size: 1.8rem;
  font-style: italic;
  line-height: 1.42;

  @include bp-medium {
    font-size: 2rem;
  }

  @include bp-large {
    font-size: 2.2rem;
  }

  @include bp-large2 {
    font-size: 2.6rem;
    margin-top: 2.4rem;
  }

  @include bp-full {
    font-size: 3rem;
    letter-spacing: -0.2px;
  }
}

.c-post-header__client,
.c-post-header__services {
  margin-top: 1.6rem;
  font-size: 1.8rem;
  line-height: 1.6;

  @include bp-medium {
    font-size: 2rem;
  }

  @include bp-large2 {
    font-size: 2.4rem;
  }

  @include bp-full {
    margin-top: 2.2rem;
  }
}

.c-post-header__services {
  margin-top: 0.8rem;

  @include bp-full {
    margin-top: 1.1rem;
  }
}

.c-post-header__client-label,
.c-post-header__services-label {
  color: $color-light-grey;
}

.c-post-header__client-link,
.c-post-header__services-link {
  @include link();
}

.c-post-header__byline {
  padding-top: 0;
  color: $color-light-grey;
  text-transform: lowercase;
  font-variant: small-caps;
  @include standard-copy-sizes;

  a {
    color: $color-light-grey;
    
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.c-post-header__date {
  white-space: nowrap;
}

.c-post-header__title-buffer {
  @include bp-large {
    margin-top: 16px;
  }
}
