.c-team-person {
  margin-bottom: 4em;

  @include bp-large {
    margin-bottom: 6em;
  }

  @include bp-large2 {
    margin-bottom: 10em;
  }
}

.c-team-person__top {
  margin-bottom: 1.6rem;

  @include bp-medium {
    margin-bottom: 2.4rem;
  }
}

.c-team-person__top-image {
  width: 100%;
}

.c-team-person__name {
  margin: 0 0 8px;
  font-weight: normal;
  font-size: 2.4rem;
  line-height: 1.2;

  @include bp-medium {
    font-size: 2.8rem;
  }

  @include bp-large2 {
    font-size: 3rem;
  }
}

.c-team-person__position {
  margin-bottom: 1.6rem;
  font-size: 1.6rem;
  font-variant: small-caps;
  color: $color-light-grey;
  text-transform: lowercase;
  line-height: 1.42;

  @include bp-medium {
    font-size: 1.8rem;
  }
}

.c-team-person__description {
  margin-bottom: 0;
  color: $color-body-text;

  @include standard-copy-sizes;

  p:last-of-type {
    margin-bottom: 0;
  }
}
