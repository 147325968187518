.o-space-for-header {
  margin-top: 140px;

  @include bp-xsmall {
    margin-top: 154px;
  }

  @include bp-small {
    margin-top: 166px;
  }

  @include bp-medium {
    margin-top: 168px;
  }

  @include bp-large {
    margin-top: 184px;
  }

  @include bp-full {
    margin-top: 310px;
  }
}
