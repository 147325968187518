.c-blog-comments {
  padding-top: 32px;

  @include bp-medium {
    padding-top: 48px;
  }

  @include bp-large {
    padding-top: 72px;
  }

  @include bp-large2 {
    padding-top: 80px;
  }
}
