.c-careers-widget__header {
  @include small-caps-title();
  line-height: 0.84;
  margin-bottom: 2rem;
  letter-spacing: -0.2px;

  @include bp-large {
    margin-bottom: 2.4rem;
  }

  @include bp-large2 {
    margin-bottom: 3.4rem;
  }
}

/* stylelint-disable rule-nested-empty-line-before */
.c-careers-widget__offers {
  // Variables
  $row-height: 130px;
  $top-bottom-padding: 26px;

  $color-offer-border: rgba(#2d302f, 0.1);
  $color-offer-department-background: $color-salmon;

  // Here we are using nested rules to style html that will be generated by
  // third party JS library

  margin-bottom: 3.2rem;

  @include bp-large {
    margin-bottom: 0;
  }

  // Reset
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0;
  }

  // Item
  .c-careers-widget__item {
    @include clearfix;
    position: relative;
    box-sizing: content-box;
    margin-bottom: 16px;

    @include bp-large {
      margin-bottom: 40px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &--has-lines {
    .c-careers-widget__item {
      border-bottom: 2px solid $color-offer-border;

      &:first-child {
        border-top: 2px solid $color-offer-border;
      }
    }
  }

  .c-careers-widget__item-info {
    clear: both;
    display: block;
    width: 100%;
  }

  // Position name
  .c-careers-widget__item-title {
    margin: 0;
    font-weight: normal;
    line-height: 1.42;

    @include standard-copy-sizes();

    @include bp-small {
      float: left;
    }

    @include bp-large {
      margin-bottom: 18px;
      width: calc(100% - 158px);
    }

    a {
      @include link();
    }
  }

  // Department
  .c-careers-widget__item-dept {
    background-color: $color-offer-department-background;
    font-size: 1.6rem;
    font-variant: small-caps;
    color: $color-white;
    text-transform: lowercase;
    display: inline-block;
    padding: 0 20px;
    height: 28px;
    line-height: 28px;
    border-radius: 14px;
    margin-bottom: $top-bottom-padding;

    display: none;

    @include bp-large {
      display: inline-block;
    }

    @include bp-full {
      font-size: 1.8rem;
    }
  }

  // Apply Now button
  .c-button {
    display: none;
    margin-bottom: 18px;

    @include bp-small {
      float: right;
      margin-bottom: 0;
    }

    @include bp-large {
      display: inline-block;
    }
  }

  @keyframes loading {
    0% {
      color: $color-medium-grey;
    }
    50% {
      color: $color-light-grey;
    }
    100% {
      color: $color-medium-grey;
    }
  }
  .whr-loading {
    animation-name: loading;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
  }

  .whr-error,
  .whr-loading {
    color: $color-medium-grey;
    font-size: 1.4rem;
    line-height: 1.42;
  }
}

/* stylelint-enable rule-nested-empty-line-before */
