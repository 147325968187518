.c-story-body {
  font-size: 0.6rem;

  @include bp-large {
    font-size: 1rem;
  }
}

.c-story-body__bg-image {
  position: relative;
  z-index: 1;
  margin: 0 auto;
  width: 100%;
  max-width: 420px;
  display: block;

  @include bp-medium {
    max-width: 1920px;
  }
}

.c-story-body__bg-image-inner {
  display: block;
  margin: auto;
  width: 100%;
}

.c-story-body__row {
  position: relative;
  display: flex;
  align-items: stretch;

  flex-direction: column-reverse;

  @include bp-medium {
    flex-direction: row;
  }

  &--image-second {
    flex-direction: column;

    @include bp-medium {
      flex-direction: row;
    }
  }

  &--center {
    justify-content: center;
  }
}

.c-story-body__column {
  width: 100%;
  padding: 40px 15px;
  display: flex;
  align-items: center;

  @include bp-medium {
    padding: 48px 15px;
    width: 50%;
  }

  @include bp-large2 {
    // min-height: 830px;
  }

  &:first-child {
    justify-content: flex-end;
  }

  &--to-bottom {
    align-items: flex-end;
    padding-bottom: 0;
  }

  &--background-left,
  &--background-right {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;

    @include bp-medium {
      width: 50%;
      height: 100%;
      min-height: 0;
    }
  }

  &--background-right {
    top: 50%;

    @include bp-medium {
      left: auto;
      right: 0;
      top: 0;
    }
  }

  &--image {
    padding: 0;
  }
}

.c-story-body__column-body {
  width: 100%;
  max-width: 730px;

  @include bp-medium {
    padding: 0 6.25%;
  }

  .c-story-body__column--image & {
    padding: 0;
  }
}

.c-story-body__heading-img {
  height: 3.5em;
  max-width: 100%;
}

.c-story-body__paragraph {
  text-align: left;
  @include links-inside(1px);
  @include standard-copy-sizes;

  p {
    margin-bottom: 24px;

    @include bp-large {
      margin-bottom: 40px;
    }
  }

  &:last-of-type p:last-of-type {
    margin-bottom: 0;
  }
}

.c-story-body__image {
  width: 100%;
  height: 100%;
  display: block;
  margin: auto;
}
