.c-standard-body__before-co {
  margin-top: 32px;
  margin-bottom: 24px;

  @include bp-large {
    margin-top: 50px;
    margin-bottom: 30px;
  }

  @include bp-large2 {
    margin-top: 70px;
    margin-bottom: 48px;
  }
}

.c-standard-body__paragraph h2 {
  @include small-caps-title();
  margin: 1em 0 2.4rem;

  @include bp-large {
    margin-bottom: 40px;
  }
}

.c-standard-body__paragraph img {
  max-width: 100%;
}

.c-standard-body__paragraph {
  color: $color-body-text;
  @include links-inside(1px);
  @include standard-copy-sizes;

  &--spacious {
    line-height: 2em;
  }

  p {
    margin-bottom: 24px;

    @include bp-large {
      margin-bottom: 40px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ul, ol {
    margin: 24px 0 24px 15px;
    position: relative;

    @include bp-large {
      margin: 40px 0 40px 20px;
    }
  }

  ul li, ol li {
    margin-bottom: 16px;
    padding-left: 8px;
  }
}
