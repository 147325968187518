.c-team-page {
  font-size: 0.6rem;
  margin-bottom: -5em;

  @include bp-large {
    font-size: 1rem;
  }
}

.c-team-page__people-filler {
  display: none;

  &:nth-child(3n) {
    @include bp-large {
      display: block;
    }
  }
}
