.c-website-footer {
  @include clearfix;
  position: relative;
  background-color: $color-white;
}

.c-website-footer__link {
  text-decoration: none;
  @include link(-1px, -0.34em, $color-blue);
  color: $color-link;
  white-space: nowrap;
  font-size: 1.8rem;
}

.c-website-footer__terms,
.c-website-footer__map {
  @include link(-1px, -0.35em, $color-blue);
  color: $color-link;
  white-space: nowrap;
}

.c-website-footer__container {
  @include clearfix;
  padding: 48px 0;

  @include bp-large2 {
    padding: 60px 0 72px;
  }

  @include bp-full {
    padding: 80px 0;
  }
}

// Bottom left
.c-website-footer__header-name {
  margin: 0 0 3px;
  font-size: 3rem;
  font-weight: normal;
  letter-spacing: -0.2px;

  @include bp-full {
    font-size: 3.6rem;
  }
}

.c-website-footer__header-desc {
  margin: 0;
  font-size: 1.5rem;
  font-weight: normal;
  color: $color-light-grey;

  @include bp-medium {
    font-size: 1.6rem;
  }

  @include bp-full {
    font-size: 1.8rem;
  }
}

.c-website-footer__info {
  margin-top: 50px;
  line-height: 1.38;
  font-size: 1.3rem;
  display: flex;

  @include bp-small {
    font-size: 1.5rem;
  }

  @include bp-medium {
    line-height: normal;
  }

  @include bp-large2 {
    font-size: 1.6rem;
  }

  @include bp-full {
    font-size: 1.8rem;
  }
}

.c-website-footer__info-logo-image {
  width: 100%;
}

.c-website-footer__info-address {
  color: $color-body-text;
  align-self: flex-end;
  white-space: nowrap;
  line-height: 1.5;
}

.c-website-footer__left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.c-website-footer__right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 50px;

  @include bp-medium {
    margin-top: 0;
  }
}

.c-website-footer__links-col {
  list-style: none;
  margin: 0;
}

.c-website-footer__linkcontainer {
  line-height: 1.8;
}

.c-website-footer__copy-social {
  margin-top: 40px;

  @include bp-medium {
    margin-top: 32px;
  }
}

.c-website-footer__copy {
  color: $color-body-text;
  font-size: 1.3rem;
  line-height: 1.5;

  @include bp-medium {
    font-size: 1.5rem;
  }

  @include bp-large2 {
    font-size: 1.6rem;
  }

  @include bp-full {
    font-size: 1.8rem;
  }
}

.c-website-footer__social {
  text-align: center;
  margin-top: 35px;

  @include bp-medium {
    margin-top: 0;
    text-align: left;
  }

  @include bp-large {
    text-align: right;
  }
}

.c-website-footer__social-link {
  margin-left: 10px;
  margin-right: 10px;
  transition: color .2s ease;

  @include bp-medium {
    margin-left: 20px;
    margin-right: 0;

    &:first-child {
      margin-left: 0;
    }
  }

  &:hover,
  &:focus {
    color: $color-dark-gray;
  }
}

.c-website-footer__social-icon {
  font-size: 24px;
  width: 24px;
  height: 20px;

  @include bp-full {
    font-size: 30px;
    width: 30px;
    height: 30px;
  }
}
