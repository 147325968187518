.c-post-body__paragraph,
.c-post-body__copy {
  color: $color-body-text;
  @include links-inside(1px);

  &--spacious {
    line-height: 2em;
  }

  ul, ol {
    margin: 24px 0 24px 15px;
    position: relative;

    @include bp-large {
      margin: 40px 0 40px 20px;
    }
  }

  ul li, ol li {
    margin-bottom: 16px;
    padding-left: 8px;
  }
}

.c-post-body__copy-button {
  margin-bottom: 32px;
}

.c-post-body__paragraph {
  @include standard-copy-sizes;

  p {
    margin-bottom: 24px;

    @include bp-large {
      margin-bottom: 40px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.c-post-body__copy {
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.42;

  @include bp-medium {
    font-size: 1.7rem;
  }

  @include bp-large {
    font-size: 1.8rem;
  }

  @include bp-large2 {
    font-size: 2rem;
  }

  p + p {
    margin-top: 24px;

    @include bp-large {
      margin-top: 32px;
    }
  }
}

.c-post-body__copy--larger {
  @include standard-copy-sizes;
  margin-bottom: 24px;
  
  @include bp-medium {
    margin-bottom: 32px;
  }
}

.c-post-body__copy--no-mb {
  margin-bottom: 0;
}

.c-post-body__row {
  padding-top: 24px;
  margin-bottom: 0;

  @include bp-small {
    padding-top: 32px;
  }

  @include bp-medium {
    padding-top: 40px;
  }

  @include bp-large {
    padding-top: 64px;
  }

  @include bp-large2 {
    padding-top: 72px;
  }

  &--no-top {
    padding-top: 0;
  }

  &--less-padding {
    padding-top: 24px;

    @include bp-medium {
      padding-top: 32px;
    }

    @include bp-large {
      padding-top: 40px;
    }

    @include bp-large2 {
      padding-top: 48px;
    }
  }

  &--more-padding {
    padding-top: 40px;

    @include bp-small {
      padding-top: 48px;
    }

    @include bp-medium {
      padding-top: 56px;
    }

    @include bp-large {
      padding-top: 72px;
    }

    @include bp-large2 {
      padding-top: 96px;
    }
  }
}

.c-post-body__row:first-child {
  padding-top: 0;
}

.c-post-body__midheading {
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 3rem;
  text-transform: uppercase;
}

.c-post-body__co {
  @include clearfix;
}

.c-post-body__co--awards-press {
  padding-top: 32px;
}

.c-post-body__co-image {
  margin-bottom: 1.6rem;
  height: 7em;

  &--placeholder {
    display: none;
    @include bp-medium {
      display: block;
    }
  }
}

.c-post-body__co-header,
.c-post-body__paragraph h2,
.c-post-body__copy h3 {
  @include small-caps-title();
  line-height: 1;
}

.c-post-body__co-header--no-mt {
  margin-top: 0;
}

.c-post-body__co-body {
  font-style: italic;
  color: $color-light-grey;
  margin: 0 0 2.4rem;
  @include standard-copy-sizes;

  @include bp-medium {
    margin: 0;
  }

  p {
    margin-bottom: 16px;

    @include bp-medium {
      margin-bottom: 24px;
    }

    @include bp-large {
      margin-bottom: 32px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  a {
    @include link();
    color: $color-light-grey;
  }

  &--no-mb {
    margin-bottom: 0;
  }
}

.c-post-body__before {
  margin-top: 20px;

  @include bp-medium {
    margin-top: 36px;
  }

  @include bp-large {
    margin-top: 60px;
  }
}

.c-post-body__before-co {
  margin-bottom: 24px;
  margin-top: 24px;

  @include bp-medium {
    margin-top: 32px;
    margin-bottom: 24px;
  }

  @include bp-large {
    margin-top: 50px;
    margin-bottom: 30px;
  }

  @include bp-large2 {
    margin-top: 70px;
    margin-bottom: 48px;
  }
}

.c-post-body__after-co--awards-press {
  margin-top: 40px;
}

.c-post-body__after-co {
  @include bp-medium {
    margin-top: 40px;
  }

  @include bp-large {
    margin-top: 60px;
  }

  @include bp-large2 {
    margin-top: 70px;
    margin-bottom: 10px;
  }
}

.c-post-body__after-co--more-posts {
  margin-bottom: 24px;
  margin-top: 32px;

  @include bp-medium {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  @include bp-large {
    margin-top: 48px;
    margin-bottom: 56px;
  }

  @include bp-large2 {
    margin-top: 72px;
    margin-bottom: 80px;
  }
}

.c-post-body__link {
  font-size: 1.8rem;

  @include bp-medium {
    font-size: 2.4rem;
  }

  @include bp-full {
    font-size: 3rem;
  }
}

.c-post-body__link-icon {
  color: #85C9D3;
  width: 24px;
  height: 24px;
  font-size: 24px;
  display: block;
  float: left;
  margin-right: 5px;

  @include bp-medium {
    margin-top: 3px;
    margin-right: 16px;
  }

  @include bp-full {
    width: 36px;
    height: 36px;
    font-size: 36px;
    margin-top: 0;
  }
}

.c-post-body__link-link {
  display: block;

  @include bp-large {
    margin-left: -40px; // 24 + 16
  }

  @include bp-full {
    margin-left: -52px; // 36 + 16
  }
}

.c-post-body__link-desc {
  @include link();
}

.c-post-body__quote-container {
  border-left: 4px solid $color-light-blue;
  padding-left: 20px;
  padding-top: 6px;

  @include bp-medium {
    border-left-width: 6px;
    padding-left: 24px;
    padding-top: 8px;
  }

  @include bp-large {
    border-left-width: 8px;
    padding-left: 30px;
    padding-top: 12px;
  }

  @include bp-large2 {
    padding-left: 36px;
  }
}

%quote-text {
  font-size: 2rem;
  font-style: italic;
  line-height: 1.25;

  @include bp-xsmall {
    font-size: 2.2rem;
  }

  @include bp-medium {
    font-size: 2.6rem;
  }

  @include bp-large {
    font-size: 3.2rem;
  }

  @include bp-large2 {
    font-size: 4rem;
  }
}

.c-post-body__quote-text {
  @extend %quote-text;

  > p {
    @extend %quote-text;
    display: inline;
    margin-bottom: 0;
  }

  &:before {
    content: '“';
    position: absolute;
    transform: translateX(-100%);
    padding-right: 3px;
  }

  &:after {
    content: '”';
    margin-left: -4px;
  }

  // ...and a tags
  @include links-inside(1px);
}

.c-post-body__quote-author {
  font-size: 1.8rem;
  font-variant: small-caps;
  color: $color-light-grey;
  text-transform: lowercase;
  margin-top: 1.3rem;

  @include bp-medium {
    font-size: 2rem;
  }

  @include bp-large {
    font-size: 2.2rem;
  }

  @include bp-large2 {
    font-size: 2.2rem;
  }

  p {
    margin-bottom: 0;
  }
}

.c-post-body__image {
  height: auto;
  display: inline-block;
  max-width: 100%;
}

.c-post-image__caption {
  font-size: 1.4rem;
  margin-top: 1rem;
  line-height: 1.34;
  color: $color-light-grey;

  @include bp-large {
    font-size: 1.5rem;
  }

  @include bp-full {
    font-size: 1.8rem;
  }

  p {
    margin-bottom: 0;
  }
}

