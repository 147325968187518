.c-class-toggler {
  @include link;
  margin-left: 4px;
}

.c-class-toggler__default {
  display: inline;
}

.c-class-toggler__alternative {
  display: none;
}

.c-class-toggler.target-class-off {
  .c-class-toggler__default {
    display: none;
  }

  .c-class-toggler__alternative {
    display: inline;
  }
}
