.c-tile {
  width: 100%;
  display: flex;
  margin-bottom: 32px;
  position: relative;
  background: $color-white;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 6px 16px 3px rgba($color-black, 0.08);

  @include bp-small {
    margin-bottom: 24px;
  }

  @include bp-medium {
    margin-bottom: 32px;
  }

  @include bp-large {
    box-shadow: 0 16px 29.44px 2.56px rgba($color-black, 0.1);
    margin-bottom: 48px;
  }

  @include bp-large2 {
    margin-bottom: 56px;
  }

  &--link {
    transition-property: transform;
    transition-duration: 0.15s;

    &:hover {
      transform: translateY(-16px);
    }
  }
}

.c-tile__inner {
  display: block;
  flex-grow: 2;
  max-width: 100%;
}

.c-tile__image-wrapper {
  position: relative;
  display: block;
  overflow: hidden;

  &:before {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.1) 0%, transparent 100%);
    bottom: 0;
    content: "";
    height: 40px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 0;
  }
}

.c-tile__image {
  // Additional border-radius has been added to avoid
  // glitches happening during transform transitions
  // detected in Chrome.
  // Artur Kot – Xfive
  border-radius: 6px 6px 0 0;
  width: 100%;
  display: block;
  height: 100%;

  &--circle {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 100%;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;

    @supports (object-fit: cover) {
      object-fit: cover;
    }
  }
}

.c-tile__content-wrapper {
  position: relative;
  background: $color-white;
  padding: 20px;
  font-size: 0.8rem;

  @include bp-small {
    padding: 20px 16px;
  }

  @include bp-medium {
    font-size: 0.9rem;
    padding: 24px 20px 20px;
  }

  @include bp-large2 {
    font-size: 1rem;
    padding: 30px;
  }

  @include bp-full {
    padding: 40px;
  }
}

.c-tile__circle {
  position: absolute;
  right: 16px;
  border: 1px solid $color-white;
  overflow: hidden;
  object-fit: cover;
  background: $color-white;
  transform: translateZ(0);
  @include avatar-with-size(48px);

  @include bp-small {
    @include avatar-with-size(42px);
    right: 8px;
  }

  @include bp-medium {
    border-width: 2px;
    @include avatar-with-size(60px);
    right: 20px;
  }

  @include bp-large2 {
    @include avatar-with-size(74px);
  }

  @include bp-full {
    right: 37px;
  }
}

.c-tile__info-text {
  margin-bottom: 1.4rem;
  font-size: 1.5rem;
  font-variant: small-caps;
  text-transform: lowercase;
  color: $color-light-grey;

  @include bp-medium {
    margin-bottom: 2rem;
  }

  @include bp-large2 {
    font-size: 1.8rem;
    letter-spacing: -0.2px;
  }

  span {
    @include bp-small {
      display: none;
    }

    @include bp-small2 {
      display: inline;
    }
  }
}

.c-tile__title {
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 1.4rem;

  @include bp-small {
    font-size: 2rem;
  }

  @include bp-medium {
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }

  @include bp-large2 {
    font-size: 3rem;
    letter-spacing: -0.2px;
  }
}

.c-tile__text {
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1.66;
  color: $color-body-text;

  @include bp-medium {
    font-size: 1.6rem;
    line-height: 1.5;
  }

  @include bp-large2 {
    font-size: 1.8rem;
  }
}

.c-tile__media {
  background: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  bottom: 0;
  left: 20px;
  padding: 12px 16px;
  position: absolute;

  @include bp-small {
    left: 16px;
  }

  @include bp-medium {
    left: 20px;
  }

  @include bp-large2 {
    left: 30px;
  }

  @include bp-full {
    left: 40px;
  }
}

.c-tile__media__outlet-image {
  display: inline-block;
  max-height: 20px;
  max-width: 120px;
  vertical-align: middle;
}
