.c-tile-square {
  position: relative;
  margin-bottom: 32px;
  border-radius: 6px;
  width: 100%;
  overflow: hidden;
  background: $color-white;
  box-shadow: 0 6px 16px 3px rgba($color-black, 0.08);

  @include bp-small {
    margin-bottom: 24px;
  }

  @include bp-medium {
    margin-bottom: 32px;
  }

  @include bp-large {
    &::before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }

  @include bp-large {
    box-shadow: 0 16px 29.44px 2.56px rgba($color-black, 0.1);
    margin-bottom: 48px;
  }

  @include bp-large2 {
    margin-bottom: 56px;
  }

  &--link {
    transition-property: transform;
    transition-duration: 0.15s;

    &:hover {
      transform: translateY(-16px);
    }
  }

  &--expand-vertically {
    &::before {
      @include bp-medium {
        padding-bottom: 112%;
      }
    }
  }
}

.c-tile-square__inner {
  display: flex;
  flex-flow: column;

  @include bp-large {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.c-tile-square__image-wrapper {
  position: relative;
  overflow: hidden;
  flex: 1 1 auto;

  @include bp-large {
    height: 1%;
  }

  &:before {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.05) 0%, transparent 100%);
    bottom: 0;
    content: "";
    height: 48px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 1;
  }
}

.c-tile-square__image {
  // Additional border-radius has been added to avoid
  // glitches happening during transform transitions
  // detected in Chrome.
  // Artur Kot – Xfive
  display: block;
  border-radius: 6px 6px 0 0;
  width: 100%;

  @include bp-large {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    min-width: 100%;
    min-height: 100%;

    @supports (object-fit: cover) {
      transform: none;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.c-tile-square__content-wrapper {
  background: $color-white;
  padding: 24px;
  font-size: 0.6rem;
  min-height: 1px;

  @include bp-small {
    padding: 16px;
  }

  @include bp-medium {
    font-size: 0.7rem;
    padding: 24px 28px;
  }

  @include bp-large {
    padding: 30px 36px;
    font-size: 0.8rem;
  }

  @include bp-full {
    padding: 40px 60px 55px;
    font-size: 1rem;
  }
}

.c-tile-square__info-text {
  margin-bottom: 1.5em;
  font-size: 2rem;
  font-variant: small-caps;
  text-transform: lowercase;
  color: $color-light-grey;
}


.c-tile-square__title {
  margin: 0;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 1.2;

  @include bp-small {
    font-size: 2.2rem;
  }

  @include bp-medium {
    font-size: 2.8rem;
  }

  @include bp-large {
    font-size: 3.2rem;
  }

  @include bp-large2 {
    font-size: 4rem;
    letter-spacing: -0.8px;
  }

  @include bp-full {
    font-size: 4.8rem;
    letter-spacing: -0.8px;
  }
}

.c-tile-square__text {
  margin: 1em 0 0;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.42;
  color: $color-body-text;

  @include bp-medium {
    font-size: 1.8rem;
  }

  @include bp-large {
    font-size: 2rem;
  }

  @include bp-large2 {
    font-size: 2.4rem;
  }
}

.c-tile-square__bottom {
  @include clearfix;
  margin-top: 1.2rem;

  @include bp-small {
    display: none;
  }

  @include bp-medium {
    display: flex;
    margin-top: 2rem;
  }

  @include bp-large2 {
    margin-top: 2.8rem;
  }

  @include bp-full {
    margin-top: 3.6rem;
  }
}

.c-tile-square__small-text {
  margin: 0;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.66;
  color: $color-light-grey;

  @include bp-medium {
    font-size: 15px;
  }

  @include bp-large {
    line-height: 1.5;
  }
}

.c-tile-square__small-image {
  height: auto;
  width: 100%;

  @include bp-medium {
    display: none;
  }

  @include bp-large {
    display: block;
  }
}
