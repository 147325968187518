.c-upload-box {
  transition: padding 0.2s, background 0.2s;
  position: relative;
  display: flex;
  margin-bottom: 28px;
  padding: 15px;
  height: 185px;
  background: rgba($color-light-grey, 0.1);
  overflow: hidden;

  &.is-drag-over {
    padding: 30px;
    background: rgba($color-light-grey, 0.15);
  }

  @include bp-medium {
    margin-bottom: 55px;
  }
}

.c-upload-box__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px dashed $color-light-grey;
  border-radius: 13px;
}

.c-upload-box__file {
  position: absolute;
  left: -9999px;
}

.c-upload-box__text {
  position: relative;
  z-index: 1;
  padding: 0 20px;
  @include standard-copy-sizes();
  color: $color-light-grey;
  text-align: center;
}

.c-upload-box__link {
  transition: border-color 0.2s;
  color: $color-text;
  border-bottom: 1px solid $color-blue;
  cursor: pointer;

  &:hover {
    border-color: $color-dark-blue;
  }

  &--try-again {
    display: none;
  }
}
