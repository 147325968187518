.o-container {
  display: block;
  margin: 0 auto;
  padding-left: $grid-gutter-width-base / 2;
  padding-right: $grid-gutter-width-base / 2;
  max-width: 1400px + $grid-gutter-width-base * 2;

  @include bp-small {
    width: 96%;
  }

  @include bp-medium {
    padding-left: $grid-gutter-width-base;
    padding-right: $grid-gutter-width-base;
    width: auto;
  }

  @include bp-full {
  }

  &--expanded {
    max-width: 1660px + $grid-gutter-width-base * 2;
  }
}
