.c-custom-select {
  background: #fff;
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: none !important;
  overflow: hidden;
  position: relative;
}

.c-custom-select__input {
  background: transparent !important;
  border-radius: 4px;
  border: 1px solid $color-light-grey;
  display: block;
  font-size: 1.8rem;
  font-weight: normal;
  padding: 1rem 68px 0.9rem 1.2rem;
  margin: 0;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-overflow: '';
  text-indent: 0.01px;
  transition: border-color 0.2s;
  width: 100%;
  z-index: 2;

  @include bp-full {
    font-size: 2.4rem;
    padding: 0.8rem 68px 0.7rem 1.6rem;
  }

  &::-ms-expand {
    display: none;
  }

  &:focus {
    outline: 0 none;
    border-color: $color-black;
  }

  option {
    color: $color-black;
  }
}

.c-custom-select__icon {
  box-sizing: border-box;
  position: absolute;
  display: flex;
  align-items: center;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  z-index: 0;
  width: 20px;
  height: 20px;

  &:before {
    font-size: 20px;
    color: $color-black;
    content: "▾";
    line-height: 1;
  }
}
