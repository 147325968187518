.c-home-careers {
  font-size: 0.7rem;
  @include bp-large {
    font-size: 1rem;
  }
}

.c-home-careers__header {
  width: 60%;
  max-width: 360px;

  @include bp-large {
    max-width: 540px;
    width: 50%;
    margin-bottom: 6rem;
  }
}

.c-home-careers__header-image {
  width: 100%;
}

.c-home-careers__info h2,
.c-home-careers__info-header {
  @include small-caps-title();
  margin-bottom: 1.4rem;

  @include bp-medium {
    margin-bottom: 1.5rem;
  }

  @include bp-large {
    margin-bottom: 2rem;
  }

  @include bp-large2 {
    margin-bottom: 3rem;
  }
}

.c-home-careers__info p,
.c-home-careers__info-body {
  font-size: 1.5rem;
  @include standard-copy-sizes();
  margin-bottom: 1.6rem;

  @include bp-medium {
    margin-bottom: 2.2rem;
  }

  @include bp-large2 {
    margin-bottom: 3.2rem;
  }

  > a {
    @include link();
  }
}
