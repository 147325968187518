.c-home-intro {
  padding-top: 3.6rem;

  @include bp-medium {
    padding-top: 4.8rem;
  }

  @include bp-full {
    padding-top: 0;
  }
}

.c-home-intro__copy {
  font-size: 1.8rem;
  line-height: 1.42;

  @include bp-medium {
    font-size: 2rem;
  }

  @include bp-large {
    font-size: 2.2rem;
  }

  @include bp-large2 {
    font-size: 2.6rem;
  }

  @include bp-full {
    font-size: 3rem;
    letter-spacing: -0.2px;
  }

  a {
    @include link();
  }
}

.c-home-intro__beard-img {
  width: 90px;

  @include bp-xsmall {
    width: 110px;
  }

  @include bp-full {
    width: 132px;
  }
}
