.c-capabilities {
  color: $color-light-grey;
  margin-bottom: 24px;
  margin-top: 3px;

  @include bp-large {
    margin-bottom: 32px;
    margin-top: 6px;
  }
}

.c-capabilities__capability {
  font-size: 1.6rem;
  font-variant: small-caps;
  text-transform: lowercase;
  color: $color-light-grey;
  margin-bottom: 8px;
  line-height: 1;

  @include bp-small {
    display: block;
  }

  @include bp-large {
    font-size: 1.7rem;
  }

  @include bp-large2 {
    font-size: 2rem;
    letter-spacing: -0.2px;
  }

  @include bp-full {
    font-size: 2.2rem;
  }

  &:after {
    display: inline-block;
    content: ", ";

    @include bp-small {
      content: none;
    }
  }

  &:last-child:after {
    content: '.';

    @include bp-small {
      content: none;
    }
  }
}
