@mixin make-cols($value: 1) {
  .o-col-#{$value} {
    @include make-col($value);
  }

  .o-col-push-#{$value} {
    @include make-col-offset($value);
  }

  .o-col-pull-#{$value} {
    @include make-col-pull($value);
  }
}

@mixin make-cols-xsmall($value: 1) {
  .o-col-xsmall-#{$value} {
    @include bp-xsmall {
      @include make-col($value);
    }
  }

  .o-col-xsmall-push-#{$value} {
    @include bp-xsmall {
      @include make-col-offset($value);
    }
  }

  .o-col-xsmall-pull-#{$value} {
    @include bp-xsmall {
      @include make-col-pull($value);
    }
  }
}

@mixin make-cols-small($value: 1) {
  .o-col-small-#{$value} {
    @include bp-small {
      @include make-col($value);
    }
  }

  .o-col-small-push-#{$value} {
    @include bp-small {
      @include make-col-offset($value);
    }
  }

  .o-col-small-pull-#{$value} {
    @include bp-small {
      @include make-col-pull($value);
    }
  }
}

@mixin make-cols-medium($value: 1) {
  .o-col-medium-#{$value} {
    @include bp-medium {
      @include make-col($value);
    }
  }


  .o-col-medium-push-#{$value} {
    @include bp-medium {
      @include make-col-offset($value);
    }
  }

  .o-col-medium-pull-#{$value} {
    @include bp-medium {
      @include make-col-pull($value);
    }
  }
}

@mixin make-cols-large($value: 1) {
  .o-col-large-#{$value} {
    @include bp-large {
      @include make-col($value);
    }
  }

  .o-col-large-push-#{$value} {
    @include bp-large {
      @include make-col-offset($value);
    }
  }

  .o-col-large-pull-#{$value} {
    @include bp-large {
      @include make-col-pull($value);
    }
  }
}

@mixin make-cols-large2($value: 1) {
  .o-col-large2-#{$value} {
    @include bp-large2 {
      @include make-col($value);
    }
  }

  .o-col-large2-push-#{$value} {
    @include bp-large2 {
      @include make-col-offset($value);
    }
  }

  .o-col-large2-pull-#{$value} {
    @include bp-large2 {
      @include make-col-pull($value);
    }
  }
}

@mixin make-cols-full($value: 1) {
  .o-col-full-#{$value} {
    @include bp-full {
      @include make-col($value);
    }
  }

  .o-col-full-push-#{$value} {
    @include bp-full {
      @include make-col-offset($value);
    }
  }

  .o-col-full-pull-#{$value} {
    @include bp-full {
      @include make-col-pull($value);
    }
  }
}

.o-row {
  @include clearfix;
  min-width: 100%;

  @include bp-small {
    margin-left: -20px;
  }

  @include bp-medium {
    margin-left: -$grid-gutter-width-base;
  }

  &--relative {
    position: relative;
  }

  &--seamless {
    margin: 0;

    [class*=col-] {
      padding: 0;
    }
  }

  &--flex {
    display: flex;
    flex-flow: row wrap;

    &::after {
      content: none;
      display: none;
    }
  }

  &--flex-space-between {
    justify-content: space-between;
  }

  &--align-center {
    align-items: center;
  }
  
  &--align-baseline {
    align-items: baseline;
  }

  &--gutter-double {
    @include bp-large2 {
      margin-left: -$grid-gutter-width-base * 2;
    }
  }
}

[class*=col-] {
  width: 100%;
  float: left;
  min-height: 1px;

  @include bp-small {
    padding-left: 20px;
  }

  @include bp-medium {
    padding-left: $grid-gutter-width-base;
  }
}

.o-col {
  &--auto {
    width: auto;
    flex: 1;
  }

  &--no-padding-left {
    padding-left: 0;
  }

  &--no-padding-right {
    padding-right: 0;
  }
}

@for $i from 0 through 16 {
  @include make-cols($i);
}

.o-col-auto {
  width: auto;
}

.o-col-third {
  width: 33.33333%;
}

.o-col-half {
  width: 50%;
}

@for $i from 0 through 16 {
  @include make-cols-xsmall($i);
}

.o-col-xsmall-auto {
  @include bp-xsmall {
    width: auto;
  }
}

.o-col-xsmall-third {
  @include bp-xsmall {
    width: 33.33333%;
  }
}

.o-col-xsmall-half {
  @include bp-xsmall {
    width: 50%;
  }
}

@for $i from 0 through 16 {
  @include make-cols-small($i);
}

.o-col-small-auto {
  @include bp-small {
    width: auto;
  }
}

.o-col-small-third {
  @include bp-small {
    width: 33.33333%;
  }
}

.o-col-small-half {
  @include bp-small {
    width: 50%;
  }
}

@for $i from 0 through 16 {
  @include make-cols-medium($i);
}

.o-col-medium-auto {
  @include bp-medium {
    width: auto;
  }
}

.o-col-medium-third {
  @include bp-medium {
    width: 33.33333%;
  }
}

.o-col-medium-half {
  @include bp-medium {
    width: 50%;
  }
}

@for $i from 0 through 16 {
  @include make-cols-large($i);
}

.o-col-large-auto {
  @include bp-large {
    width: auto;
  }
}

.o-col-large-third {
  @include bp-large {
    width: 33.33333%;
  }
}

.o-col-large-half {
  @include bp-large {
    width: 50%;
  }
}

@for $i from 0 through 16 {
  @include make-cols-large2($i);
}

.o-col-large2-auto {
  @include bp-large2 {
    width: auto;
  }
}

.o-col-large2-third {
  @include bp-large2 {
    width: 33.33333%;
  }
}

.o-col-large2-half {
  @include bp-large2 {
    width: 50%;
  }
}

@for $i from 0 through 16 {
  @include make-cols-full($i);
}

.o-col-full-auto {
  @include bp-full {
    width: auto;
  }
}

.o-col-full-third {
  @include bp-full {
    width: 33.33333%;
  }
}

.o-col-full-half {
  @include bp-full {
    width: 50%;
  }
}

.o-col-gutter-double {
  @include bp-large2 {
    padding-left: $grid-gutter-width-base * 2;
  }
}

.o-col-gutter-half {
  @include bp-medium {
    padding-left: $grid-gutter-width-base / 2;
  }
}
