.c-client-logos {
  margin-top: 40px;

  @include bp-medium {
    margin-top: 80px;
  }

  @include bp-large {
    margin-top: 80px;
  }

  @include bp-large2 {
    margin-top: 80px;
  }
}

.c-client-logos__client {
  margin-bottom: 28px;
  padding: 0 16px;
  text-align: center;

  @include bp-small {
    margin-bottom: 40px;
    padding: 0 32px;
  }

  @include bp-medium {
    padding: 0 40px;
  }

  @include bp-large2 {
    margin-bottom: 64px;
  }
}

.c-client-logos__title {
  @include small-caps-title();
  letter-spacing: -0.2px;

  @include bp-large2 {
    margin-bottom: 1.9em;
  }
}

.c-client-logos__image {
  max-height: 64px;
  max-width: 124px;
  height: 100%;
  width: 100%;

  @include bp-medium {
    max-height: 76px;
    max-width: 148px;
  }

  @include bp-large {
    max-height: 106px;
    max-width: 206px;
  }

  @include bp-full {
    max-height: 130px;
    max-width: 254px;
  }
}
