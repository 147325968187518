.c-separator {
  height: 2px;

  &--thick {
    height: 4px;

    @include bp-medium {
      height: 6px;
    }

    @include bp-large {
      height: 8px;
    }
  }

  &--full-width {
    width: 100%;
  }

  // Colors:

  &--light-blue {
    background-color: $color-light-blue;
  }

  &--light-grey {
    background-color: $color-light-grey;
  }

  &--lighter-grey {
    background-color: $color-lighter-grey;
  }
}
