.c-main-nav {
  margin-top: 160px;

  @include bp-xsmall {
    margin-top: 180px;
  }

  @include bp-large {
    margin-top: 240px;
  }
}

.c-main-nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 3.2rem;
  line-height: 1.6;

  @include bp-small {
    font-size: 3.6rem;
  }

  @include bp-medium {
    line-height: 1.43;
    font-size: 4.8rem;
  }

  @include bp-large {
    font-size: 6rem;
  }

  @include bp-large2 {
    font-size: 7rem;
  }
}

.c-main-nav__item {
  margin: 0;
}

.c-main-nav__link {
  @include link(-2px, -7px);
  letter-spacing: -0.5px;

  @include bp-full {
    @include link(-3px, -10px);
    letter-spacing: -1.5px;
  }

  &--on-layer {
    color: $color-white !important;
    @include link(-2px, -7px, $color-white);
  }
}

.c-main-nav__small-list {
  list-style: none;
  margin: 0;
  font-size: 2.4rem;
  margin-top: 40px;
  margin-bottom: 10px;

  display: flex;
  // justify-content: space-between;

  @include bp-small {
    font-size: 2.8rem;
  }

  @include bp-medium {
    font-size: 3.2rem;
    margin-top: 60px;
  }
}

.c-main-nav__small-item {
  margin: 0 3.2rem 0 0;

  @include bp-small {
    margin-right: 4rem;
  }

  @include bp-medium {
    margin-right: 4.8rem;
  }
}

.c-main-nav__small-link {
  color: $color-white !important;
  @include link(-2px, -5px, $color-white);

  @include bp-full {
    @include link-variation(-3px, -7px, $color-white);
  }
}
