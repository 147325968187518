.c-button {
  $border-radius: 3px;
  $bg-color: $color-dark-blue;
  $hover-color: $color-less-dark-blue;

  padding: 14px 24px 13px;
  border-radius: $border-radius;
  background-color: $bg-color;
  z-index: 126;
  color: $color-white;
  font-size: 16px;
  font-variant: small-caps;
  line-height: 1;
  text-transform: lowercase;
  transition: background-color ease .2s;
  text-align: center;
  display: inline-block;

  @include bp-full {
    font-size: 17px;
    padding: 16px 30px 15px;
  }

  &:hover,
  &:focus {
    background-color: $hover-color;
    color: $color-white;

    .c-button__icon {
      color: $color-white;
    }
  }

  &.is-loading {
    position: relative;
    cursor: progress;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: $border-radius;
      background: $bg-color url('../assets/images/loading.svg') no-repeat 50% 50%;
      background-size: auto 60%;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.7;
  }
}

.c-button--large {
  font-size: 19px;
  padding: 15px 24px 16px;
  
  @include bp-full {
    font-size: 20px;
    padding: 18px 30px 19px;
  }
}

.c-button--small {
  font-size: 17px;
  padding: 11px 16px 12px;
  
  @include bp-large {
    font-size: 18px;
    padding: 14px 26px 13px;
  }
}

.c-button__icon {
  color: $color-white;
}

.c-button--ghost {
  background-color: transparent;
  border: 1px solid $color-dark-blue;
  color: $color-dark-blue;

  .c-button__icon {
    color: $color-dark-blue;
  }
}

.c-button__icon--arrow:after {
  content: "➔";
  display: inline-block;
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  margin-left: 6px;
  margin-top: -2px;
  position: relative;
  vertical-align: middle;
}
