.c-custom-checkbox {
  $size: 25px;

  display: inline-block;
  position: relative;
  width: $size;
  height: $size;
  min-width: $size; // Prevents from squashing by flex layout
  border: 1px solid $color-blue;
  margin-right: 15px;
  vertical-align: middle;
}

.c-custom-checkbox__input {
  appearance: none;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;

  &:checked ~ .c-custom-checkbox__check {
    background: $color-blue;
  }

  &:focus ~ .c-custom-checkbox__check {
    box-shadow: 0 0 3px $color-blue inset;
    outline: 1px solid darken($color-blue, 15%);
  }
}

.c-custom-checkbox__check {
  transition: background-color 0.2s;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;

  &::before {
    $width: 10px;
    $height: 6px;
    $border-width: 2px;

    content: '';
    transform: rotate(-45deg);
    position: absolute;
    left: calc(50% - #{$width / 2 - $border-width / 2});
    top: calc(50% - #{$height / 2});
    width: $width;
    height: $height;
    border-left: $border-width solid $color-white;
    border-bottom: $border-width solid $color-white;
  }
}
