.c-village-speak {
  margin-bottom: 46px;
  margin-top: 44px;

  @include bp-medium {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  @include bp-large {
    margin-top: 150px;
    margin-bottom: 100px;
  }
}

.c-village-speak__header {
  margin-bottom: 1rem;
  width: 100%;

  @include bp-medium {
    margin-bottom: 2rem;
    width: 75%;
  }
}

.c-village-speak__header-image {
  width: 100%;
}
