.c-blog-accessible-pagination {
  display: none;
  
  .no-js &,
  .template-blog-amp & {
    display: block;
  }
}

.c-blog-accessible-pagination__link {
  margin-right: 20px;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.42;
  @include link();

  @include bp-full {
    font-size: 1.8rem;
  }

  &:last-child {
    margin-right: 0;
  }
}