.c-category-nav {
  margin-top: 32px;
}

.c-category-nav__option {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 16px;
}

.c-category-nav__link {
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid $color-green;
  color: $color-green;
  display: inline-block;
  font-size: 1.6rem;
  font-variant: small-caps;
  line-height: 1;
  padding: 6px 12px 7px;
  outline: 0;
  text-align: center;
  text-transform: lowercase;
  transition: background-color ease .15s, opacity ease .15s;

  &:hover,
  &:focus {
    color: $color-green;
    opacity: 0.66;
  }

  @include bp-large {
    font-size: 1.8rem;
  }

  @include bp-full {
    font-size: 2rem;
  }

  .c-category-nav__option--active & {
    background-color: $color-green;
    color: #fff;

    &:hover,
    &:focus {
      background-color: $color-light-green;
      color: #fff;
      opacity: 1;
    }
  }
}
