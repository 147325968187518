.c-form {
  @include bp-small {
    padding: 24px;
    background: #C2E4E8;
    color: #000;
  }

  @include bp-medium {
    padding: 32px;
  }
}
