.c-standard-header__heading {
  margin-bottom: 0;
  font-size: 2.6rem;
  font-weight: normal;
  line-height: 1.2;

  @include bp-medium {
    font-size: 3.6rem;
  }

  @include bp-large {
    font-size: 4rem;
  }

  @include bp-large2 {
    font-size: 6rem;
  }

  @include bp-full {
    line-height: 1.14;
    font-size: 8.4rem;
  }
}
