.c-share-snippet__quote {
  background: rgba(#c1e4ea, 0.5);
}

.c-share-snippet__popup {
  display: inline-block;
}

a.c-share-snippet__twitter {
  color: #00a2ee;
}

a.c-share-snippet__linkedin {
  color: #0077B2;
}

a.c-share-snippet__icon {
  @include link-variation(0, 0);
  box-sizing: content-box;
  display: inline-block;
  font-size: 14px;
  margin-left: 2px;
  padding: 0 4px;

  @include bp-medium {
    font-size: 16px;
  }

  @include bp-large {
    font-size: 18px;
  }

  &:first-child {
    margin-left: 6px;
  }
}

.c-share-snippet__icon-share {
  color: $color-dark-blue;
  font-size: 16px;
}
