.c-header-separator {
  margin-bottom: 24px;
  margin-top: 24px;

  @include bp-medium {
    margin-top: 32px;
    margin-bottom: 40px;
  }

  @include bp-large {
    margin-top: 48px;
    margin-bottom: 56px;
  }

  @include bp-large2 {
    margin-top: 72px;
    margin-bottom: 80px;
  }
}
