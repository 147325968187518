.c-post-navigation {
  margin-bottom: 0;
  margin-top: 2.4rem;
  position: relative;
  line-height: 1.42;

  @include bp-small {
    position: static;
  }

  @include bp-medium {
    margin-top: 2rem;
  }

  @include bp-large2 {
    margin-top: 2.4rem;
  }
}

.c-post-navigation__label {
  @include small-caps-title();
  margin-bottom: 12px;
  line-height: 1;
  margin-top: 4px;

  @include bp-large {
    font-size: 2.4rem;
  }

  @include bp-large2 {
    margin-top: 6px;
  }
}

.c-post-navigation__link {
  font-size: 1.8rem;
  @include link();

  @include bp-large2 {
    font-size: 2rem;
  }

  @include bp-full {
    font-size: 2.4rem;
  }
}
