.c-work {
  margin-top: 40px;

  @include bp-medium {
    margin-top: 96px;
  }

  @include bp-full {
    margin-top: 150px;
  }
}

.c-work__header {
  margin-bottom: 1rem;
  width: 65%;

  @include bp-medium {
    margin-bottom: 2rem;
    width: 49%;
  }
}

.c-work__header-image {
  width: 100%;
}

.c-work__container {
  position: relative;
}

@keyframes normalEyePaths {
  0%, 42% {
    visibility: visible;
  }
  44%, 46% {
    visibility: hidden;
  }
  47%, 82% {
    visibility: visible;
  }
  84%, 86% {
    visibility: hidden;
  }
  87%, 88% {
    visibility: visible;
  }
  89%, 91% {
    visibility: hidden;
  }
  95%, 100% {
    visibility: visible;
  }
}

@keyframes blinkEyePaths {
  0%, 42% {
    visibility: hidden;
  }
  44%, 46% {
    visibility: visible;
  }
  47%, 82% {
    visibility: hidden;
  }
  84%, 86% {
    visibility: visible;
  }
  87%, 88% {
    visibility: hidden;
  }
  89%, 91% {
    visibility: visible;
  }
  95%, 100% {
    visibility: hidden;
  }
}

.c-work__container-binoculars-guy {
  position: absolute;
  top: 0;
  right: 7%;
  width: 16%;
  transform: translateY(-100%);
  display: none;

  @include bp-medium {
    display: block;
  }

  svg {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;

    #left-eye path,
    #right-eye path {
      @include bp-medium {
        animation: normalEyePaths 8.5s infinite;
      }
    }

    path#left-eye-blink,
    path#right-eye-blink {
      @include bp-medium {
        visibility: hidden;
        animation: blinkEyePaths 8.5s infinite;
      }
    }
  }
}

.c-work__container-binoculars-guy__dummy {
  display: block;
  visibility: hidden;
  width: 100%;
}

.c-work__articles {
  margin-bottom: 24px;

  @include bp-medium {
    margin-bottom: auto;
  }
}
