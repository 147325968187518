.c-move-the-needle {
  overflow: hidden;
  margin-bottom: 88px;
  margin-top: 44px;

  @include bp-medium {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  @include bp-large2 {
    margin-bottom: 150px;
  }
}

.c-move-the-needle--inline {
  @include bp-medium {
    margin-top: 72px;
    margin-bottom: 64px;
  }
}

.c-move-the-needle__arrow {
  position: relative;
  top: 1vw;
  margin: 0 auto;
  max-width: 260px;
  z-index: 1;

  @include bp-small {
    margin-bottom: -24px;
    transform: rotateZ(-15deg);
  }

  @include bp-small2 {
    margin-bottom: 8px;
    max-width: none;
    transform: rotateZ(15deg);
  }

  @include bp-large {
    transform: rotateZ(10deg);
  }
}

.c-move-the-needle__arrow--mobile {
  display: block;

  @include bp-small2 {
    display: none;
  }
}

.c-move-the-needle__arrow--desktop {
  display: none;

  @include bp-small2 {
    display: block;
  }
}

.c-move-the-needle__bg {
  bottom: 0;
  left: -48px;
  height: 100%;
  max-width: none;
  position: absolute;
  right: -48px;
  top: 0;
  width: calc(100% + 96px);
  z-index: -1;

  @include bp-small {
    left: 0;
    right: 0;
    width: 100%;
  }
}

.c-move-the-needle__copy {
  padding: 40px 24px 40px;
  position: relative;
  margin-top: -16px;
  z-index: 0;

  @include bp-small {
    padding: 48px 32px;
  }
}

.c-move-the-needle__text {
  text-align: left;

  @include bp-medium {
    text-align: left;
  }

  > p {
    font-size: 1.8rem;
    line-height: 1.42;

    @include bp-medium {
      font-size: 1.8rem;
    }

    @include bp-large {
      font-size: 2rem;
    }

    @include bp-full {
      font-size: 2.4rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include links-inside();
}

.c-move-the-needle__cta {
  margin: -24px 24px 0 24px;
  text-align: right;
  position: relative;
  z-index: 2;

  @include bp-small {
    margin: -24px 48px 0;
  }

  @include bp-medium {
    text-align: right;
    margin: -24px 56px 0;
  }

  .c-button {
    text-align: center;
    width: 100%;

    @include bp-small {
      width: auto;
    }
  }
}