// Headings

h1, h2, h3, h4, h5, h6 {
  color: $color-text;
  font-weight: normal;
  line-height: 1.2;
  margin: 0 0 8px;

  @include bp-medium {
    margin-bottom: 16px;
  }
}

h4, h5, h6 {
  line-height: 1.375;
}

h1, .h1 {
  font-size: 3.4rem;
  font-weight: normal;
  line-height: 1.2;
  letter-spacing: -0.01em;

  @include bp-small {
    font-size: 3.6rem;
  }

  @include bp-medium {
    font-size: 4.2rem;
  }

  @include bp-large {
    font-size: 4.8rem;
  }

  @include bp-large2 {
    font-size: 6.4rem;
    line-height: 1;
  }

  @include bp-full {
    font-size: 7rem;
    letter-spacing: -0.02em;
  }
}

h2, .h2 {
  font-size: 3rem;

  @include bp-small {
    font-size: 3.2rem;
  }

  @include bp-medium {
    font-size: 3.8rem;
  }

  @include bp-large {
    font-size: 4rem;
  }
}

h3, .h3 {
  font-size: 2.6rem;

  @include bp-small {
    font-size: 2.8rem;
  }

  @include bp-medium {
    font-size: 3rem;
  }

  @include bp-large {
    font-size: 3.2rem;
  }
}

h4, .h4 {
  font-size: 2.2rem;

  @include bp-small {
    font-size: 2.4rem;
  }

  @include bp-medium {
    font-size: 2.6rem;
  }

  @include bp-large {
    font-size: 2.8rem;
  }
}

h5, .h5 {
  font-size: 1.8rem;
  font-weight: 600;

  @include bp-small {
    font-size: 2rem;
  }

  @include bp-medium {
    font-size: 2.2rem;
  }

  @include bp-large {
    font-size: 2.4rem;
  }
}

h6, .h6 {
  font-size: 1.6rem;
  font-weight: 600;

  @include bp-small {
    font-size: 1.8rem;
  }

  @include bp-medium {
    font-size: 2rem;
  }

  @include bp-large {
    font-size: 2.2rem;
  }
}
