.c-blog-post-page {
  font-size: 0.6rem;
  position: relative;

  @include bp-medium {
    padding-bottom: 3.2rem;
  }

  @include bp-large {
    font-size: 1rem;
  }
}

.c-blog-post-page__content {
  position: relative;
}
