// Links

a {
  color: $color-link;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $color-text;
    outline-color: $color-green;
  }
}
