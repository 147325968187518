// Page

html {
  background: $color-bg;
  color: $color-text;
  font-family: $font-default;
  font-size: 0.625rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-moz-selection{
  background: $color-blue;
}

::selection {
  background: $color-blue;
}