.c-contact-body__title {
  @include small-caps-title();
  
  @include bp-full {
    margin-bottom: 1.9em;
  }
}

.c-contact-body__text {
  // CMS requires targeting p...
  > p {
    color: $color-darker-gray;
    margin: 0 0 1.5rem;

    @include standard-copy-sizes;
  }

  // ...and a tags
  @include links-inside(1px);
}
