.c-header-menu {
  display: flex;
  align-items: flex-start;
  margin-top: 2.2rem;

  @include bp-medium {
    margin-top: 5.4rem;
  }

  @include bp-large {
    margin-top: 5.8rem;
  }

  @include bp-full {
    margin-top: 11.8rem;
  }

  &.is-sticked {
    transform: translateY(-22px);

    @include bp-medium {
      transform: translateY(-54px);
    }

    @include bp-large {
      transform: translateY(-44px);
    }

    @include bp-full {
      transform: translateY(-104px);
    }
  }
}
