.c-menu-layer {
  position: fixed;
  top: -9999em;
  left: -9999em;
  width: 100%;
  height: 100%;
  background-color: $color-green;

  z-index: 500;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;

  &.has-scroll {
    overflow-y: scroll;
  }
}
