.u-flex {
  display: flex;
  flex-flow: row wrap;

  &--space-between {
    justify-content: space-between;
  }

  &--align-bottom {
    align-items: flex-end;
  }
}
