.c-content-strip {
  margin-top: 2.4rem;
  @include links-inside(1px);
  @include standard-copy-sizes;

  @include bp-medium {
    margin-top: 5.6rem;
  }


  &:first-child {
    margin-top: 0;
  }

  h2 {
    @include small-caps-title();

    @include bp-large {
      margin-bottom: 2.6rem;
    }
  }

  a h2 {
    @include link();
  }

  p {
    color: $color-darker-gray;
    margin-bottom: 24px;

    @include bp-large {
      margin-bottom: 40px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ul, ol {
    margin: 24px 0 24px 15px;
    position: relative;

    @include bp-large {
      margin: 40px 0 40px 20px;
    }
  }

  ul li, ol li {
    color: $color-darker-gray;
    margin-bottom: 16px;
    padding-left: 8px;
  }
}
