.c-menu-trigger {
  background-color: $color-green;
  height: 40px;
  border-radius: 4rem;
  text-transform: lowercase;
  font-variant: small-caps;
  font-size: 1.6rem;
  outline: 0;
  transition: background-color ease 200ms, width ease 200ms;
  width: 108px;
  flex: auto 0 0;

  &:hover,
  &:focus {
    background-color: $color-light-green;
  }

  // @include bp-medium {
  //   width: 4.8rem;
  //   width: 140px;
  //   height: 4.8rem;
  //   font-size: 1.8rem;
  // }

  @include bp-large {
    display: none;
  }

  @include bp-full {
    width: 5.4rem;
    width: 148px;
    height: 5.4rem;
    font-size: 2rem;
  }

  &--on-layer {
    background-color: $color-white;

    &:hover,
    &:focus {
      background-color: $color-white;
    }
  }
}

.c-menu-trigger__text {
  display: inline-block;
  line-height: 2rem;
  vertical-align: middle;
  color: $color-white;
  transition: width ease 200ms, opacity ease 200ms;
  
  // @include bp-medium {
  //   margin-top: -2px;
  // }

  &--on-layer {
    color: $color-green;

    .c-menu-trigger:hover &,
    .c-menu-trigger:focus & {
      color: $color-dark-green;
    }
  }
}

.c-menu-trigger__icon {
  position: relative; 
  display: inline-block;
  vertical-align: middle;
  margin-left: 1.5rem;
  width: 18px;
  height: 18px;
  border-right: 0 none;
  transition: all ease .35s;

  // @include bp-medium {
  //   width: 25px;
  //   height: 20px;
  //   margin-left: 1.5rem;
  //   margin-bottom: -2px;
  // }

  .is-open & {
    transform: scale(0.778);

    // @include bp-medium {
    //   transform: scale(0.7) translateY(-2px) translateX(-2px);
    //   margin-left: 1.5rem;
    // }
  }
}

.c-menu-trigger__icon_stroke {
  background: $color-white;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  transition: height ease .35s, transform ease .35s;

  .is-open & {
    height: 3px;

    // @include bp-medium {
    //   height: 4px;
    // }
  }

  &--1 {
    top: 0;

    .is-open & {
      transform: translateY(7px) scale(0);
    }
  }

  &--2 {
    top: 7px;

    .is-open & {
      transform: rotateZ(45deg);
    }
  }

  &--3 {
    top: 7px;

    .is-open & {
      transform: rotateZ(-45deg);
    }
  }

  &--4 {
    top: 14px;

    .is-open & {
      transform: translateY(-7px) scale(0);
    }
  }

  .c-menu-trigger__icon--on-layer & {
    background: $color-green;
  }

  .c-menu-trigger:hover .c-menu-trigger__icon--on-layer &,
  .c-menu-trigger:focus .c-menu-trigger__icon--on-layer & {
    background: $color-dark-green;
  }
}