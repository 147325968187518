.c-teaser {
  margin-bottom: 56px;

  @include bp-medium {
    margin-bottom: 72px;
  }

  @include bp-large {
    margin-bottom: 120px;
  }
}

.c-teaser__summary {
  @include standard-copy-sizes;
  margin-bottom: 24px;

  @include bp-large {
    margin-bottom: 32px;
  }
}

.c-teaser__heading {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 12px;

  @include bp-small {
    font-size: 2.2rem;
  }

  @include bp-medium {
    font-size: 2.8rem;
    margin-bottom: 24px;
  }

  @include bp-large2 {
    font-size: 3.2rem;
  }

  @include bp-full {
    font-size: 3.4rem;
  }
}

.c-teaser__read-more {
  @include link();
  font-size: 2rem;

  @include bp-medium {
    font-size: 2.2rem;
  }

  @include bp-large {
    font-size: 2.4rem;
  }
}
