.c-header-nav {
  display: none;
  opacity: 1;
  
  @include bp-large {
    display: block;
    margin-top: 64px;
    margin-left: auto;
    margin-right: 6.25%;
  }

  @include bp-full {
    margin-top: 128px;
  }

  &.is-sticked {
    transform: translateY(-44px);
  
    @include bp-full {
      transform: translateY(-108px);
    }
  }
}

.c-header-nav__primary,
.c-header-nav__secondary {
  @extend .o-list-bare;
  display: flex;
  justify-content: flex-end;
}

.c-header-nav__secondary {
  margin-bottom: 16px;
}

.c-header-nav__item {
  margin-right: 32px;
  
  @include bp-large2 {
    margin-right: 40px;
  }

  &:last-child {
    @include bp-large {
      margin-right: 0;
    }
  }
}

.c-header-nav__link {
  font-size: 2rem;
  line-height: 1.42;
  margin-top: 30px;
  @include link(-2px, -7px);

  @include bp-large {
    font-size: 2.4rem;
  }

  @include bp-large2 {
    font-size: 2.6rem;
  }

  .is-current & {
    @include link(-7px, -7px);
  }
}

.c-header-nav__link--primary {

}
