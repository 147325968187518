.c-careers-does {
  margin-bottom: 4rem;

  @include bp-medium {
    margin-bottom: 8rem;
  }
}

.c-careers-does__row {
  display: flex;
  flex-direction: column;

  @include bp-medium {
    flex-direction: row;
  }
}

.c-careers-does__column {
  flex: 0 0 auto;
  width: 100%;
  @include bp-medium {
    width: 50%;
  }
}

.c-careers-does__column-left {
  background-color: #fafec1;
}

.c-careers-does__column-right {
  background-color: #9994f2;
}

.c-careers-does__body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  // based on o-container
  width: 100%;
  margin: 0 auto;
  padding-right: $grid-gutter-width-base / 2;
  padding-left: $grid-gutter-width-base / 2;
  max-width: (1400px + $grid-gutter-width-base * 2)/2;

  @include bp-large {
    padding-right: $grid-gutter-width-base;
    padding-left: $grid-gutter-width-base;
  }

  .c-careers-does__column-left > & {
    @include bp-large {
      margin-left: auto;
      margin-right: 0;
    }
  }

  .c-careers-does__column-right > & {
    @include bp-large {
      margin-left: 0;
      margin-right: auto;
    }
  }
}

.c-careers-does__header {
  padding-top: 4rem;
  padding-bottom: 10%;
  width: 100%;

  @include bp-medium {
    padding-top: 6rem;
  }
}

.c-careers-does__image {
  display: block;
  width: 100%;
  height: auto;
}

.c-careers-does__list,
.c-careers-does__body ul {
  line-height: 1.34;
  list-style: none;
  margin: 0 0 32px;
}

.c-careers-does__body-body {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

.c-careers-does__list-element,
.c-careers-does__body ul li {
  margin-bottom: 1em;
}

.c-careers-does__body-list {
  .c-careers-does__column-right & {
    color: $color-white;
    @include bp-large2 {
      order: 2;
    }

    @include bp-medium {
      text-align: right;
    }
  }
  padding-top: 3rem;
  align-self: flex-start;
}
