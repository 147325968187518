.c-work-page {
  font-size: 0.6rem;
  position: relative;

  @include bp-medium {
    font-size: 0.8rem;
    padding-bottom: 3.2rem;
  }

  @include bp-large {
    font-size: 1rem;
  }
}
