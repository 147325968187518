.o-header {
  font-size: 0.7rem;
  padding-top: 3.6rem;

  @include bp-medium {
    padding-top: 4.8rem;
    font-size: 1rem;
  }

  @include bp-full {
    padding-top: 0;
  }
}

.o-header__heading {
  margin: 0;
}

.o-header__heading-image {
  display: block;
  width: 100%;
  height: auto;
}

.o-header__info {
  font-size: 1.8rem;
  margin: 2.8rem 0 3rem;
  letter-spacing: -0.2px;
  line-height: 1.42;

  @include bp-medium {
    font-size: 2rem;
    letter-spacing: -0.1px;
    margin: 2em 0;
  }

  @include bp-large {
    font-size: 2.2rem;
  }

  @include bp-large2 {
    font-size: 2.6rem;
  }

  @include bp-full {
    font-size: 3rem;
    letter-spacing: -0.2px;
  }
}

.o-header__info--separator {
  margin-bottom: 0;
}

.o-header__info-text {
    // CMS requires targeting p...
  > p {
    margin-bottom: 0;
  }

  // ...and a tags
  @include links-inside(1px);
}
