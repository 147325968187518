.u-color-white {
  color: $color-white;
}

.u-color-black {
  color: $color-black;
}

.u-color-lighter-grey {
  color: $color-lighter-grey;
}

.u-color-light-grey {
  color: $color-light-grey;
}

.u-color-medium-grey {
  color: $color-medium-grey;
}

.u-color-dark-gray {
  color: $color-dark-gray;
}

.u-color-darker-gray {
  color: $color-darker-gray;
}

.u-color-light-blue {
  color: $color-light-blue;
}

.u-color-blue {
  color: $color-blue;
}

.u-color-dark-blue {
  color: $color-dark-blue;
}

.u-color-red {
  color: $color-red;
}

.u-color-pink {
  color: $color-pink;
}

.u-color-green {
  color: $color-green;
}

