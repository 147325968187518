.c-contact-form {
  // Here we are using nested rules to style html that will be generated by
  // third party JS library

  .parsley-errors-list {
    margin: -20px 0 16px;
    list-style: none;
    color: $color-error;
    font-size: 1.6rem;

    @include bp-large {
      margin: -40px 0 35px;
    }

    li {
      padding-left: 0;
    }
  }
}

.c-contact-form__label {
  display: block;
  margin-bottom: 0.4em;
  font-size: 1.5rem;
  font-variant: small-caps;
  text-transform: lowercase;
  cursor: pointer;
  line-height: 1.2;

  @include bp-medium {
    font-size: 1.6rem;
  }

  @include bp-large {
    font-size: 1.8rem;
  }
}

.c-contact-form__label--checkbox {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
}

.c-contact-form__check-label {
  display: inline-block;
  font-size: 1.6rem;
  vertical-align: middle;

  @include bp-large {
    font-size: 1.8rem;
  }
}

.c-contact-form__fieldset {
  border-width: 0;
  margin-bottom: 0;
  min-width: 0;
  max-width: 100%;
  width: 100%;
}

.c-contact-form__input,
.c-contact-form__textarea {
  transition: border-color 0.2s;
  border: 1px solid $color-light-grey;
  border-radius: 3px;
  padding: 1rem 1.2rem 0.9rem;
  margin-bottom: 24px;
  width: 100%;
  background: $color-white;
  font-size: 1.8rem;
  color: $color-text;

  @include bp-large {
    margin-bottom: 44px;
  }

  @include bp-full {
    font-size: 2.4rem;
    padding: 0.8rem 1.6rem 0.7rem;
  }

  @include placeholder-styles {
    opacity: 1;
    color: #bbb;
  }

  &:focus {
    outline: 0 none;
    border-color: $color-black;
  }

  &.parsley-error {

    @include placeholder-styles {
    }
  }
}

.c-contact-form__textarea {
  height: 160px;
  resize: vertical;
}

.c-contact-form__item {
  margin-bottom: 28px;

  @include bp-large {
    margin-bottom: 44px;
  }
}

.c-contact-form-intro {
  h2 {
    @include small-caps-title();

    @include bp-large {
      margin-bottom: 2.6rem;
    }
  }

  p {
    color: $color-darker-gray;
    margin: 0 0 24px;
    font-weight: normal;
    @include standard-copy-sizes;

    @include bp-large {
      margin-bottom: 40px;
    }

    &:last-of-type {
      @include bp-medium {
        margin-bottom: 0;
      }
    }
  }
}

.c-contact-form-intro__separator {
  border-bottom: 2px solid $color-lighter-grey;
  padding-bottom: 0;
  margin-bottom: 4rem;

  @include bp-medium {
    padding-bottom: 8rem;
    margin-bottom: 8rem;
  }
}

.c-contact-form__message{
  color: $color-darker-gray;
  margin: 36px 0 24px 0;
  font-weight: normal;
  @include standard-copy-sizes;

  @include bp-large {
    margin-bottom: 40px;
  }
}

.c-errors__error {
  color: #da3f3f;
}
