.c-newsletter {
  $gradient-bp: 210px;
  $gradient-bp-medium: 55px;

  position: relative;
  overflow: hidden;
  background: $color-white;

  &::before {
    $bleed: 15px;

    content: '';
    position: absolute;
    right: $bleed * -1;
    bottom: 0;
    left: $bleed * -1;
    height: 300px;
    background: url('../assets/images/footer/wave.svg') 50% 0;
    background-size: cover;

    @include bp-medium {
      height: 160px;
    }

    @include bp-full {
      height: 200px;
    }
  }

  // Here we are using nested rules to style html that will be generated by
  // third party JS library

  .parsley-errors-list {
    position: absolute;
    margin: 3px 0;
    list-style: none;
    color: $color-dark-gray;
    text-align: right;
    font-size: 1.2rem;
    width: 100%;

    @include bp-small {
      width: calc(100% - 30px);
    }
    @include bp-medium {
      margin: 8px 0;
      font-size: 1.6rem;
      width: auto;
    }
  }
}

.c-newsletter__container {
  position: relative;
  height: 100%;
}

.c-newsletter__backgroundimage {
  position: absolute;
  right: -90px;
  width: 270px;
  bottom: 220px;

  @include bp-small {
    right: -100px;
    width: 350px;
    bottom: 205px;
  }

  @include bp-medium {
    right: 0;
    bottom: 75px;
    width: 380px;
  }

  @include bp-large2 {
    width: 480px;
    bottom: 55px;
  }

  @include bp-full {
    right: auto;
    left: 50%;
    margin-left: 270px;
    width: 640px;
    bottom: 40px;
  }
}

.c-newsletter__header {
  position: relative;
  width: 100%;
  max-width: 600px;

  @include bp-medium {
    max-width: 450px;
  }

  @include bp-large {
    width: 60%;
    max-width: 600px;
  }

  @include bp-large2 {
    max-width: none;
    margin-bottom: 42px;
    width: 68.75%;
  }
}

.c-newsletter__header-image {
  width: 100%;
}

.c-newsletter__body {
  position: relative;
  margin: 56px auto 0;

  @include bp-medium {
    margin-top: 64px;
  }
  
  @include bp-large {
    margin-bottom: 20px;
    margin-top: 80px;
  }

  @include bp-large2 {
    margin-top: 144px;
  }

  @include bp-full {
    margin-bottom: 30px;
    margin-top: 200px;
  }

  .c-move-the-needle + .c-newsletter & {
    @include bp-large {
      margin-top: 40px;
    }
  }
}

.c-newsletter__info {
  // CMS requires targeting p...
  > p {
    position: relative;
    top: 8%;
    font-size: 1.5rem;
    line-height: 1.42;
    margin-bottom: 64px;

    @include bp-small {
      font-size: 1.6rem;
      margin-bottom: 100px;
    }

    @include bp-medium {
      font-size: 1.8rem;
    }

    @include bp-large {
      font-size: 2rem;
    }

    @include bp-full {
      font-size: 2.4rem;
    }
  }

  // ...and a tags
  @include links-inside(1px);
}

.c-newsletter__form {
  position: relative;
  display: flex;
  flex-direction: column;

  @include bp-medium {
    margin-bottom: 10px;
    flex-direction: row;
  }

  @include bp-large {
    flex-direction: row;
    margin-bottom: 30px;
  }

  @include bp-full {
    margin-bottom: 30px;
  }
}

.c-newsletter__form-group {
  margin-bottom: 14px;

  @include bp-medium {
    margin-bottom: 0;
  }
}

.c-newsletter__form-label {
  font-size: 1.5rem;
  font-variant: small-caps;
  color: $color-dark-gray;
  text-transform: lowercase;
  margin-bottom: 8px;
  line-height: 2rem;

  @include bp-medium {
    font-size: 1.6rem;
  }

  @include bp-medium {
    line-height: 1.6em;
  }

  @include bp-full {
    font-size: 1.8rem;
  }
}

.c-newsletter__form-input {
  font-size: 1.8rem;
  border-color: $color-white;
  padding: 1rem 1.2rem 0.9rem;

  @include bp-full {
    font-size: 2.4rem;
    padding: 0.8rem 1.6rem 0.7rem;
  }

  &:hover {
    cursor: pointer;
  }

  @include placeholder-styles {
    color: #bbb;
  }

  &:focus {
    border-color: mix($color-dark-gray, $color-pink, 20%);
    cursor: text;

    @include placeholder-styles {
      color: $color-white;
    }
  }

  &.parsley-error {
    $error-color: lighten($color-red, 7%);
    border-color: $error-color;
    box-shadow: inset 0 0 8px 0 $error-color;
  }
}

.c-newsletter__form-button-container {
  margin-top: 10px;
  align-self: flex-end;

  @include bp-medium {
    margin-top: 15px;
  }
}

$border-radius: 3px;
$bg-color: $color-dark-blue;
$hover-color: $color-less-dark-blue;

.c-newsletter__form-button {
  align-self: flex-end;
  width: 100%;

  @include bp-small {
    width: auto;
  }
}
