.c-newsletter-message-box {
  color: #fff;
  font-size: 1.2rem;
  margin: 8px 0;
  height: 30px;

  @include bp-large {
    font-size: 1.4rem;
    position: absolute;
    top: calc(100% - 21px);
    margin: auto;
  }
}