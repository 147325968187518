.c-case-study-teaser {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 6px 16px 3px rgba(44, 47, 46, 0.08);
  display: block;
  margin-bottom: 32px;
  position: relative;

  @include bp-small {
    align-items: flex-start;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
  }

  @include bp-large {
    box-shadow: 0 16px 29.44px 2.56px rgba(44, 47, 46, 0.1);
    margin-bottom: 48px;
  }

  transition-property: box-shadow;
  transition-duration: 0.15s;

  &:hover {
    box-shadow: 0 16px 29.44px 2.56px rgba(44, 47, 46, 0.2);
  }
}

.c-case-study-teaser__content {
  background: $color-white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 24px;
  font-size: 0.6rem;
  min-height: 1px;

  @include bp-small {
    padding: 16px;
    flex-shrink: 0;
    width: 60%;
  }

  @include bp-medium {
    font-size: 0.7rem;
    padding: 24px 28px;
  }

  @include bp-large {
    padding: 30px 36px;
    font-size: 0.8rem;
  }
}

.c-case-study-teaser__summary {
  margin: 1em 0 0;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.42;
  color: $color-body-text;

  @include bp-medium {
    font-size: 1.8rem;
  }

  @include bp-large {
    font-size: 2rem;
  }
}

.c-case-study-teaser__heading {
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 1.2;
  margin: 0;

  @include bp-small {
    font-size: 2.2rem;
  }

  @include bp-medium {
    font-size: 2.8rem;
  }

  @include bp-large {
    font-size: 3.2rem;
  }
}

.c-case-study-teaser__bottom {
  margin-top: 1.6rem;
}

.c-case-study-teaser__read-more {
  @include link();
  white-space: nowrap;
  font-size: 1.8rem;

  @include bp-medium {
    font-size: 2rem;
  }

  @include bp-large {
    font-size: 2.2rem;
  }
}

.c-case-study-teaser__image-link {
  display: none;

  @include bp-small {
    bottom: 0;
    display: block;
    margin-right: 32px;
    position: absolute;
    left: 0;
    top: 0;
    width: 40%;
  }
}

.c-case-study-teaser__image-link--mobile {
  display: block;

  @include bp-small {
    display: none;
  }
}

.c-case-study-teaser__image {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  display: block;
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  height: 100%;

  @include bp-small {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

.c-case-study-teaser__bottom {
  @include bp-large {
    align-items: center;
    display: flex;
  }
}

.c-case-study-teaser__key-achievement {
  margin: 0;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.66;
  color: darken($color-light-grey, 10%);

  @include bp-medium {
    font-size: 15px;
  }

  @include bp-large {
    color: $color-light-grey;
    line-height: 1.5;
  }
}