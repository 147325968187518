// Mixins

/* 375px */
@mixin bp-xsmall {
  @media (min-width: $bp-xsmall) {
    @content;
  }
}

/* 480px */
@mixin bp-small {
  @media (min-width: $bp-small) {
    @content;
  }
}

/* Max width 479px */
@mixin bp-small-max {
  @media (max-width: $bp-small - 1) {
    @content;
  }
}

/* 600px */
@mixin bp-small2 {
  @media (min-width: $bp-small2) {
    @content;
  }
}

/* 768px */
@mixin bp-medium {
  @media (min-width: $bp-medium) {
    @content;
  }
}

/* Max width 767px */
@mixin bp-medium-max {
  @media (max-width: $bp-medium - 1) {
    @content;
  }
}

/* 1024px */
@mixin bp-large {
  @media (min-width: $bp-large) {
    @content;
  }
}

/* 1200px */
@mixin bp-large2 {
  @media (min-width: $bp-large2) {
    @content;
  }
}

/* 1600px */
@mixin bp-full {
  @media (min-width: $bp-full) {
    @content;
  }
}

/* 1800px */
@mixin bp-jumbo {
  @media (min-width: $bp-jumbo) {
    @content;
  }
}

@mixin menu-animate-opacity {
  opacity: 0;
  transition: opacity $menu-animation-time;

  &.is-showed {
    opacity: 1;
  }
}

@mixin text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin link($heightInitial: -1px, $heightFull: -0.3em, $shadow-color: $color-blue) {
 box-shadow: inset 0 $heightInitial 0 $shadow-color;
 color: $color-link;
 padding-bottom: 2px;
 text-decoration: none;
 transition: 0.2s box-shadow ease;

 &:hover,
 &:focus {
   box-shadow: inset 0 $heightFull 0 $shadow-color;
 }
}

@mixin link-variation($heightInitial: -1px, $heightFull: -0.3em, $shadow-color: $color-blue) {
  &:not(:hover):not(:focus) {
    box-shadow: inset 0 $heightInitial 0 $shadow-color;
  }

  &:hover,
  &:focus {
    box-shadow: inset 0 $heightFull 0 $shadow-color;
  }
}

@mixin links-inside($heightInitial: -1px, $heightFull: -0.3em, $shadow-color: $color-blue) {
 a {
   @include link($heightInitial: -1px, $heightFull: -0.3em, $shadow-color: $color-blue);
 }
}

// Why not just "placeholder"?
// Because it could collide with BS mixin.
// Artur Kot
@mixin placeholder-styles {
  $placeholders: ':-webkit-input' ':-moz' '-moz' '-ms-input';

  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      @content;
    }
  }
}

@mixin standard-copy-sizes {
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.42;

  @include bp-medium {
    font-size: 1.8rem;
  }

  @include bp-large {
    font-size: 2rem;
  }

  @include bp-large2 {
    font-size: 2.4rem;
  }
}

@mixin small-caps-title {
  font-size: 2rem;
  font-variant: small-caps;
  font-weight: 500;
  text-transform: lowercase;


  @include bp-medium {
    font-size: 2.4rem;
  }

  @include bp-large2 {
    font-size: 3rem;
  }
}

@mixin avatar-with-size($size) {
  top: -($size*0.666);
  border-radius: $size/2;
  width: $size;
  height: $size;
}
