.c-website-header {
  padding-top: 1rem;
  height: 135px;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 400;
  width: 100%;

  transform: translate3d(0, 0, 0);

  @include bp-medium {
    margin: 0;
  }

  &.is-fixed {
    position: fixed;
  }

  &.initial-scroll {
    position: absolute;
  }

  &.is-sticked {
    position: fixed;
    height: 70px;
    overflow: hidden;
    background: linear-gradient(to bottom, $color-white 0, $color-white 60px
    , rgba(0, 0, 0, 0) 60px, rgba(0, 0, 0, 0) 100%);

    @include bp-large {
      height: 130px;
      background: linear-gradient(to bottom, $color-white 0, $color-white 100px
      , rgba(0, 0, 0, 0) 100px, rgba(0, 0, 0, 0) 100%);
    }

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 10px;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 80%);
      position: absolute;
      top: 60px;
      opacity: 1;
      transition: opacity $menu-shadow-animation-time;

      @include bp-large {
        height: 30px;
        top: 100px;
      }
    }
  }

  &.is-near-top {
    overflow: visible;

    &:after {
      opacity: 0;
      z-index: -2;
    }
  }
}

.c-website-header__inner {
  display: flex;
  justify-content: space-between;

  @include bp-large {
    justify-content: flex-start;
  }
}

#TVOU,
.is-near-top #TVOU {
  display: inline;
  opacity: 0;
  transition: opacity ease 150ms, transform ease 150ms 150ms;
}

#Letters_1_,
.is-near-top #Letters_1_ {
  opacity: 1;
  transition: all ease 300ms 150ms;
  transform-origin: center;
}

#Ellipse,
.is-near-top #Ellipse {
  display: inline;
  stroke: #fff;
  stroke-dasharray: 995px; // 2 * PI * r (158.3)
}

.is-sticked {
  #TVOU {
    opacity: 1;
    transition: opacity ease 300ms, transform ease 300ms;
    transform: translateX(-70px);

    @include bp-large {
      transform: translateX(0);
    }
  }

  #Letters_1_ {
    opacity: 0;
    transition: opacity ease 0s;
    transform: scale(0.92);
  }
}

.is-near-top #TVOU {
  transform: none;
}

.c-website-header__logo {
  margin: 2.7rem 0 0 0;
  width: 25%;
  max-width: 100px;
  flex: auto 0 0;

  @include bp-medium {
    margin-left: 0;
  }

  @include bp-large {
    max-width: 110px;
  }

  @include bp-full {
    margin: 8.3rem 0 0;
    max-width: 125px;
  }

  &.has-no-animation {
    transition: none;
  }

  &--on-layer {
    transition: transform $menu-animation-time, opacity $menu-animation-time;
    transform: translateY(0) !important;
    opacity: 0;

    &.is-menu-open {
      opacity: 1;
    }
  }

  &.is-hidden {
    opacity: 0;
  }

  &.is-sticked {
    transform: translateY(-58%);

    @include bp-large {
      transform: translateY(-40%);
    }

    @include bp-full {
      transform: translateY(-85%);
    }
  }

  &--on-layer {
    &.is-sticked {
      transform: none;
    }
  }
}

.c-website-header__title {
  display: none;

  @include bp-medium {
    @include text-overflow;
    flex: auto 1 1;
    margin: 0 16px 0 24px;
    height: 3.2rem;
    display: block;
  }

  @include bp-medium {
    font-size: 2.4rem;
    margin-top: 6rem;
  }

  @include bp-large {
    height: 4rem;
    margin-left: 50px;
    margin-top: 6.8rem;
  }

  @include bp-full {
    font-size: 3rem;
    margin-top: 12.8rem;
  }

  &.is-sticked {
    @include bp-medium {
      transform: translateY(-54px);
    }

    @include bp-large {
      transform: translateY(-44px);
    }

    @include bp-full {
      transform: translateY(-104px);
    }
  }
}

.c-website-header__title-part1 {
  @include link(-2px, -7px);
}

.c-website-header__title-separator {
  color: $color-light-grey;

  ~ .c-website-header__title-part1 {
    margin-right: 1.2rem;
  }
}

.c-website-header__title-part2 {
  @include text-overflow;
  color: $color-light-grey;
}
