.c-tvou-logo {
  position: relative;
  overflow: hidden;
  margin: 0;
  width: 100%;
  padding-bottom: 99%;
  height: 0;
  text-indent: -999em;
}

.c-tvou-logo__link,
.c-tvou-logo__link svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-menu-layer .c-tvou-logo__link {
  background: url('../assets/images/tvou-logo.svg') no-repeat 50% 50%;
  background-size: contain;
}
