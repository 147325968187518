.u-text-align-left {
  text-align: left;
}

.u-text-align-right {
  text-align: right;
}

.u-text-align-center {
  text-align: center;
}

.u-text-align-medium-left {
  @include bp-medium {
    text-align: left;
  }
}

.u-text-align-medium-right {
  @include bp-medium {
    text-align: right;
  }
}

.u-text-align-medium-center {
  @include bp-medium {
    text-align: center;
  }
}
