.c-workshop-teaser {
  margin-bottom: 56px;

  @include bp-medium {
    margin-bottom: 72px;
  }

  @include bp-large {
    margin-bottom: 96px;
  }
}

.c-workshop-teaser__content {
}

.c-workshop-teaser__summary {
  font-size: 1.8rem;

  @include bp-large {
    font-size: 2.2rem;
  }
}

.c-workshop-teaser__heading {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 16px;

  @include bp-small {
    font-size: 2.2rem;
  }

  @include bp-medium {
    font-size: 2.8rem;
    margin-bottom: 24px;
  }

  @include bp-large2 {
    font-size: 3.2rem;
  }

  @include bp-full {
    font-size: 3.4rem;
  }
}

.c-workshop-teaser__read-more {
  @include link();
  font-size: 2rem;

  @include bp-medium {
    font-size: 2.2rem;
  }

  @include bp-large {
    font-size: 2.4rem;
  }
}
