// Utilities

/* Hide visually but not from screen readers */
.u-hide {
  left: -9999em !important;
  position: absolute !important;
}

.u-spacer {
  margin-bottom: 1rem;
}

.u-noscroll {
  overflow: hidden;
}

.u-fixed {
  position: fixed;
}

.u-hide\@xsmall {
  display: none;

  @include bp-xsmall {
    display: block;
  }
}

.u-show\@xsmall {
  display: block;

  @include bp-xsmall {
    display: none;
  }
}

.u-section-margin {
  margin-top: 24px;

  @include bp-medium {
    margin-top: 40px;
  }

  @include bp-large {
    margin-top: 72px;
  }
}
