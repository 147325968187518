.c-service-section {
  margin-top: 24px;

  @include bp-medium {
    margin-top: 40px;
  }

  @include bp-large {
    margin-top: 72px;
  }
}

.c-service-section--first {
  margin-top: 0;
}

.c-service-section__row--medium {
  @include bp-medium {
    margin-top: 24px;
  }
}

.c-service-section__row--large {
  margin-top: 24px;

  @include bp-medium {
    margin-top: 48px;
  }
}

.c-service-section__row--reverse-order {
  display: flex;
  flex-direction: column-reverse;
}

.c-service-section__row--center {
  @include bp-medium {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.c-service-section__row--bottom {
  @include bp-medium {
    display: flex;
    flex-direction: row;
    align-items: bottom;
  }
}

.c-service-section__block {
  margin-bottom: 16px;
}

.c-service-section__block--last-block {
  margin-bottom: 40px;
}

.c-service-section__title {
  padding-top: 12px;

  @include bp-large2 {
    margin-bottom: 24px;
  }
}

.c-service-section__block ul, .c-service-section__block ol {
  margin-top: 0 0 40px 20px;
}

.c-service-section__block {
  ul, ol, p {
    + ul, + ol {
      margin-top: 24px;

      @include bp-large {
        margin-top: 32px;
      }
    }
  }
}
