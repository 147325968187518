.c-post-social {
  margin-bottom: 2.4rem;
  padding-left: ($grid-gutter-width-base / 2) - 10px;

  @include bp-small {
    padding-left: $grid-gutter-width-base - 10px;
  }

  @include bp-medium {
    position: absolute;
    right: 0;
    top: calc(100vw * (560 / 1920) / 2);
    bottom: calc(100vw * (560 / 1920) / 2);
    pointer-events: none;
    z-index: 100;
  }
}

.c-post-social__inner {
  @include bp-medium {
    display: block;
    transform: translateY(-50%);
    position: absolute;
    top: 0;
    right: 0;
    width: 6.25vw;
    border: 1px solid #dae5e4;
    border-right-width: 0;
    background-color: $color-white;
    pointer-events: auto;
    max-width: 64px;

    @supports (position: -webkit-sticky) {
      position: -webkit-sticky;
      top: 50%;
    }

    @supports (position: sticky) {
      position: sticky;
      top: 50%;
    }

    &.is-fixed {
      position: fixed;
      top: 50%;
    }

    &.is-docked {
      position: absolute;
      top: auto;
      bottom: 0;
    }
  }
}

.c-post-social__link {
  display: inline-block;
  text-align: center;
  padding: 8px 10px;

  @include bp-medium {
    display: block;
  }

  @include bp-large {
    padding: 10px;
  }

  @include bp-large2 {
    padding: 12px;
  }

  @include bp-full {
    padding: 15px 10px 14px;
  }

  &:first-child {
    @include bp-medium {
      margin-top: 5px;
    }

    @include bp-full {
      margin-top: 10px;
    }
  }

  &:last-child {
    @include bp-medium {
      margin-bottom: 6px;
    }

    @include bp-full {
      margin-bottom: 10px;
    }
  }
}

.c-post-social__icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
  display: inline-block;
  transition: color .2s ease;

  @include bp-full {
    width: 30px;
    height: 30px;
    font-size: 30px;
  }

  &:hover,
  &:focus {
    color: $color-dark-gray;
  }
}
